import PageHeader from "app/shared/PageHeader";
import "./Hotels.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";

import { Button, Modal, Tabs, Tag } from "antd";

import {
  API_ENDPOINT_FETCH_AIRLINE_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_CLUSTOR_EMAIL_LIST,
  API_ENDPOINT_FETCH_EMAIL_CREDENTIALS_LIST,
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
} from "../Credentials/credentials.constants";
import { apiGet } from "app/services/apiServices";

import Loader from "app/shared/Loader";
import HotelDashboard from "./components/HotelDashboard";
import HotelRecon from "./components/HotelRecon";
import SearchInput from "app/shared/SearchInput";
export default function Hotels(props: any) {
  const [activeTab, setActiveTab] = useState("1");
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [searchQuery, setSearchQuery] = useState("");
  const items = [
    {
      key: "1",
      label: "Dashboard",
    },
    {
      key: "2",
      label: "Reconciliation",
    },
  ];

  const renderScene = () => {
    switch (activeTab) {
      case "1":
        return <HotelDashboard />;
      case "2":
        return <HotelRecon textQuery={searchQuery} />;

      default:
        return <HotelDashboard />;
    }
  };

  return (
    <div className="Flights">
      <div className="ScreenContainer">
        <PageHeader
          leftActions={
            <Tabs
              items={items}
              tabBarStyle={{
                borderBottom: "none", // Remove the underline border
              }}
              activeKey={activeTab}
              onChange={(tabItem: string) => setActiveTab(tabItem)}
            />
          }
          rightActions={
            activeTab === "2" ? (
              <div style={{ width: 220 }}>
                <SearchInput onSearch={(query: string) => console.log(query)} />
              </div>
            ) : null
          }
        />

        <div className="TableContainer">{renderScene()}</div>
      </div>
    </div>
  );
}
