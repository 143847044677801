import React, { useState } from "react";
import "./HotelRecon.scss";
import PageHeader from "app/shared/PageHeader";
import { AgTableClient } from "app/shared/AgTable";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";

import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";

import JsonData from "./demo.json";
import { useRecoilState } from "recoil";
import { currentPDFData } from "app/config/States/users";
import { Modal } from "antd";
import OneImg from "./1st.png";
import TwoImg from "./2nd.png";
import ThreeImg from "./3rd.png";

const imageMap: any = {
  "1": OneImg,
  "2": TwoImg,
  "3": ThreeImg,
};
const initialColDef = [
  {
    headerName: "Status",
    children: [
      {
        headerName: "Invoice Received Status",
        field: "Invoice Received Status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Invoice Received", "Invoice Not Received"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "Invoice Received": "green",
              "Invoice Not Received": "red",
            },
            icon: {
              "Invoice Received": <CheckCircleFilled />,
              "Invoice Not Received": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "e-Invoice Received",
        field: "e-Invoice Received",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Invoice Recieved", "Invoice Not Received"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "Invoice Received": "green",
              "Invoice Not Received": "red",
            },
            icon: {
              "Invoice Received": <CheckCircleFilled />,
              "Invoice Not Received": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "Expense Match Status",
        field: "Expense Match Status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["Perfect Match", "Mismatch", "Missing invoice"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "Perfect Match": "green",
              Mismatch: "red",
              "Missing invoice": "red",
            },
            icon: {
              "Perfect Match": <CheckCircleFilled />,
              Mismatch: <CloseCircleFilled />,
              "Missing invoice": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "2B status",
        field: "2B status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["in 2B", "Not in 2B", "Missing invoice"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "In 2b": "green",
              "Not in 2B": "red",
              "Missing invoice": "red",
            },
            icon: {
              "In 2b": <CheckCircleFilled />,
              "Not in 2B": <CloseCircleFilled />,
              "Missing invoice": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
    ],
  },

  {
    headerName: "SAP Concur",
    children: [
      {
        headerName: "Employee First Name",
        field: "Employee First Name",
        filter: "agTextColumnFilter",
        formatType: "PERSON",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
      {
        headerName: "Employee Last Name",
        field: "Employee Last Name",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
      {
        headerName: "Date",
        field: "Date",
        filter: "agDateColumnFilter",
        ...handleCellRenderer(),
        formatType: "DATE",
        columnGroupShow: "open",
      },
      {
        headerName: "Employee ID",
        field: "Employee ID",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Report Number",
        field: "Report Number",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
      {
        headerName: "Transaction Amount",
        field: "Transaction Amount",
        filter: "agNumberColumnFilter",
        formatType: "AMOUNT",
        ...handleCellRenderer(),
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Report Id",
        field: "Report Id",
        filter: "agTextColumnFilter",
        columnGroupShow: ["open", "close"],
      },
    ],
  },

  {
    headerName: "Invoice",
    children: [
      {
        headerName: "Invoice Number",
        field: "Invoice Number",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Invoice Date",
        field: "Invoice Date",
        filter: "agDateColumnFilter",
        ...handleCellRenderer(),
        formatType: "DATE",
        columnGroupShow: "open",
      },
      {
        headerName: "GST Amount",
        field: "GST Amount",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Total Tax Amount",
        field: "Total Tax Amount",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Subtotal",
        field: "Subtotal",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Invoice Amount",
        field: "Invoice Amount",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        enableValue: true,
        formatType: "AMOUNT",
        columnGroupShow: ["open", "close"],
      },
    ],
  },

  {
    headerName: "GSTR 2B",
    children: [
      {
        headerName: "Supplier Name",
        field: "Supplier Name",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        formatType: "ENTITY",
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Buyer GSTIN",
        field: "Buyer GSTIN",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
      {
        headerName: "Supplier GSTIN",
        field: "Seller GSTIN",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "Rate",
        field: "Rate",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
      {
        headerName: "Taxable Value",
        field: "Taxable Value",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "IGST",
        field: "IGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        columnGroupShow: "open",
        enableValue: true,
      },
      {
        headerName: "CGST",
        field: "CGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "SGST",
        field: "SGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "Legal Name",
        field: "Legal Name",
        filter: "agTextColumnFilter",
        ...handleCellRenderer(),
        formatType: "ENTITY",
        columnGroupShow: "open",
      },
      {
        headerName: "Invoice Value",
        field: "Invoice Value",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: ["open", "close"],
      },

      {
        headerName: "GENDT",
        field: "GENDT",
        filter: "agDateColumnFilter",
        formatType: "DATE",
        ...handleCellRenderer(),
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "IRN",
    children: [
      {
        headerName: "IRN Status",
        field: "IRN Status",
        enableRowGroup: true,
        formatType: "MATCH",
        ...handleCellRenderer(),
        filter: "agSetColumnFilter",
        filterParams: {
          values: ["In IRN", "Not In IRN", "NA"], // Predefined values for the selector
        },
        columnGroupShow: ["open", "close"],
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              "In IRN": "green",
              "Not In IRN": "red",
              NA: "red",
            },
            icon: {
              "In IRN": <CheckCircleFilled />,
              "Not In IRN": <CloseCircleFilled />,
              NA: <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
      {
        headerName: "IRN",
        field: "IRN",
        filter: "agTextColumnFilter",
        handleCellRenderer,
        columnGroupShow: ["open", "close"],
      },
      {
        headerName: "IRN Date",
        field: "IRN Date",
        filter: "agDateColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Supplier GSTIN",
        field: "Supplier GSTIN",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "Buyer GSTIN",
        field: "Buyer GSTIN",
        filter: "agTextColumnFilter",
        columnGroupShow: "open",
      },
      {
        headerName: "IGST",
        field: "IGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        columnGroupShow: "open",
        enableValue: true,
      },
      {
        headerName: "CGST",
        field: "CGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },
      {
        headerName: "SGST",
        field: "SGST",
        filter: "agNumberColumnFilter",
        ...handleCellRenderer(),
        formatType: "AMOUNT",
        enableValue: true,
        columnGroupShow: "open",
      },

      {
        headerName: "Eligibilty",
        field: "Eligibilty",
        filter: "agSetColumnFilter",
        ...handleCellRenderer(),
        formatType: "MATCH",
        columnGroupShow: "open",

        filterParams: {
          values: ["Eligible", "Not Eligible"], // Predefined values for the selector
        },
        pivot: true,
        formatProps: {
          matchLogic: {
            matchColor: {
              Eligible: "green",
              "Not Eligible": "red",
            },
            icon: {
              Eligible: <CheckCircleFilled />,
              "Not Eligible": <CloseCircleFilled />,
            },
          },
          defaultText: "Not Available",
        },
      },
    ],
  },
];

export default function HotelRecon(props: any) {
  const [pdfViewerData, setPdfViewerData] = useState("");
  const [_, setCurrentPDFData] = useRecoilState(currentPDFData);
  const [showModal, setShowModal] = useState("");
  const openSidebarRenderer = (params: any) => {
    console.log("pdf data:", params);
    setCurrentPDFData(params?.data);
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

  // { headerName: "Invoice", field: "Invoice", filter: "agTextColumnFilter" },
  // {
  //   headerName: "INVOICE LINK",
  //   field: "INVOICE_LINK",
  //   filter: "agTextColumnFilter",
  // },
  const columnDefs = [
    {
      headerName: "Supplier Name",
      formatType: "CUSTOM_RENDER",
      width: 320,
      ...handleCellRenderer(),
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                color:
                  parseInt(params?.["Booking GST Difference"]) < 0
                    ? "#dd5061"
                    : "",
                fontWeight:
                  parseInt(params?.["Booking GST Difference"]) < 0 ? 700 : 400,
              }}
              onClick={() => setShowModal("1")}
            >
              <CorporateFareRoundedIcon
                style={{ fontSize: 19, marginRight: 4 }}
              />

              {params?.["Supplier Name"]}
            </div>
          );
        },
        valueGetterData: (data: any) => data?.["Booking GST Difference"],
      },
    },

    {
      headerName: "Invoice",
      field: "INVOICE_LINK",
      filter: "agTextColumnFilter",
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: (params: any) => {
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
          data: params.data?.INVOICE_LINK || params?.value,
        };
      },
    },

    {
      headerName: "E-Invoice",
      field: "eInvoice Link",
      filter: "agTextColumnFilter",
      invoiceLink: "html.pdf",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: (params: any) => {
        return {
          label: "View E-Invoice",
          handleClick: openSidebarRenderer,
          data: params.data?.["eInvoice Link"] || params?.value,
        };
      },
    },

    ...initialColDef,
  ];
  return (
    <div className="FlightRecon" style={{ height: "100%" }}>
      <AgTableClient
        /* @ts-ignore */
        columnDefs={columnDefs}
        rowData={JsonData}
        groupPanel
        enablePdfViewer
        pdfDataKey="Invoice_Link"
        pdfV2
      />

      <Modal
        closable={false}
        onCancel={() => setShowModal("")}
        open={showModal !== ""}
        footer={false}
        width={800}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <img
            src={imageMap[showModal]}
            alt="Image"
            style={{ objectFit: "contain", width: "100%" }}
          />
        </div>
      </Modal>
    </div>
  );
}
